import apiAxios, { axiosPublic } from "../libs/axios";
import { jsonToQueryString } from "../utils/Common";
const getAllCustomers = (filter = {}) => apiAxios.get(`/api/v1/customers/${jsonToQueryString(filter)}`);
const getCustomerById = (id = "") => apiAxios.get(`/api/v1/customers/${id}`);
const addCustomer = (body) => apiAxios.post("/api/v1/customers", body);
const updateCustomer = (id, body, isIntegratedPage) => {
  if (isIntegratedPage) {
    return axiosPublic.put(`/api/v1/public/customers/${id}`, body);
  } else {
    return apiAxios.put(`/api/v1/customers/${id}`, body);
  }
};
const deleteCustomer = (id) => apiAxios.delete(`/api/v1/customers/${id}`);
const getAllCustomerBookings = (id, filter = {}) => apiAxios.get(
  `/api/v1/customer/${id}/bookings${jsonToQueryString(filter)}`
);
const getAllCustomerOrders = (id, filter = {}) => apiAxios.get(
  `/api/v1/customer/${id}/orders${jsonToQueryString(filter)}`
);
const getAllCustomerMemberships = async (customerId, filter = {}, isIntegratedPage = false) => {
  if (isIntegratedPage) {
    return await axiosPublic.get(
      `/api/v1/public/customer/${customerId}/memberships${jsonToQueryString(filter)}`
    );
  } else {
    return await apiAxios.get(
      `/api/v1/customer/${customerId}/memberships${jsonToQueryString(filter)}`
    );
  }
};
const signupCustomer = (body) => apiAxios.post("/api/v1/customer/signup", body);
const signinCustomerWithPassword = (body) => apiAxios.post("/api/v1/customer/login", body);
const sendOtp = (body, isIntegratedPage) => {
  if (isIntegratedPage) {
    return axiosPublic.post("/api/v1/public/send-otp", body);
  } else {
    return apiAxios.post("/api/v1/send-otp", body);
  }
};
const verifyOtp = (body, isIntegratedPage) => {
  if (isIntegratedPage) {
    return axiosPublic.post("/api/v1/public/verify-otp", body);
  } else {
    return apiAxios.post("/api/v1/verify-otp", body);
  }
};
const customerSignout = () => apiAxios.post("/api/v1/customer/logout");
const customerGoogleSignin = () => axiosPublic.post("/api/v1/customer/auth/google");
const customerGoogleSigninCallback = (callbackParams, config = {}) => axiosPublic.get(
  `/api/v1/customer/auth/google/callback${callbackParams}`,
  config
);
const customerLoginForgotPassword = (body) => axiosPublic.post(
  "/api/v1/customer/password/forgot",
  body
);
const customerLoginResetPassword = (body) => axiosPublic.post("/api/v1/customer/password/reset", body);
const getAuthCustomer = async () => await apiAxios.post("/api/v1/customer/authenticated", {
  headers: {
    withCredentials: false
  }
});
const updateCustomerV2 = (id, body, headers) => apiAxios.post(`/api/v1/customers/${id}`, body, headers);
const updateCustomerMembershipStatus = (membershipId, body) => apiAxios.post(
  `/api/v1/customer-membership/${membershipId}`,
  body
);
const saveCardInStripe = async (customerId, body) => await apiAxios.post(
  `/api/v1/customers/${customerId}/save-card`,
  body
);
const getSavedCardsOfCustomer = async (customerId) => await apiAxios.get(`/api/v1/customers/${customerId}/cards`);
const setSavedCardAsDefault = async (customerId, savedCardId) => await apiAxios.post(
  `/api/v1/customers/${customerId}/cards/${savedCardId}/assign-default`
);
export {
  addCustomer,
  customerGoogleSignin,
  customerGoogleSigninCallback,
  customerLoginForgotPassword,
  customerLoginResetPassword,
  customerSignout,
  deleteCustomer,
  getAllCustomerBookings,
  getAllCustomerMemberships,
  getAllCustomerOrders,
  getAllCustomers,
  getAuthCustomer,
  getCustomerById,
  getSavedCardsOfCustomer,
  saveCardInStripe,
  sendOtp,
  setSavedCardAsDefault,
  signinCustomerWithPassword,
  signupCustomer,
  updateCustomer,
  updateCustomerMembershipStatus,
  updateCustomerV2,
  verifyOtp
};
