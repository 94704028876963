import AddModeIcon from "./svg/add-mode.svg";
import AddNotesIcon from "./svg/add-notes.svg";
import AddIcon from "./svg/add-two.svg";
import AddUserIcon from "./svg/add-user.svg";
import AdjacentItemIcon from "./svg/adjacent-item.svg";
import ColumnsIcon from "./svg/align-top-two.svg";
import AnalysisIcon from "./svg/analysis.svg";
import ApprovedCheckIcon from "./svg/approved-check.svg";
import ArrowLeftIcon from "./svg/arrow-left.svg";
import ArrowRightIcon from "./svg/arrow-right.svg";
import AttentionIcon from "./svg/attention.svg";
import BankCardIcon from "./svg/bank-card.svg";
import BezierCurveIcon from "./svg/bezier-curve.svg";
import BillIcon from "./svg/bill.svg";
import CancelBookingIcon from "./svg/calendar-thirty-two.svg";
import CalendarDaysIcon from "./svg/calendar-three.svg";
import CalendarIcon from "./svg/calendar.svg";
import CaretDownIcon from "./svg/caret-down.svg";
import CashReportIcon from "./svg/cash-report.svg";
import CautionIcon from "./svg/caution.svg";
import ChartRingIcon from "./svg/chart-ring.svg";
import CheckCorrectIcon from "./svg/check-correct.svg";
import CheckListIcon from "./svg/check-list.svg";
import GreenCheckIcon from "./svg/check-one.svg";
import CheckSmallIcon from "./svg/check-small.svg";
import CheckoutPlus from "./svg/checkoutPlus.svg";
import CityIcon from "./svg/city.svg";
import RedCloseIcon from "./svg/close-one.svg";
import CloseCircleIcon from "./svg/close-two.svg";
import CloseIcon from "./svg/close.svg";
import CodeIcon from "./svg/code.svg";
import CommissionIcon from "./svg/commission.svg";
import CommunicationIcon from "./svg/communication.svg";
import ComputerIcon from "./svg/computer.svg";
import ConnectIcon from "./svg/connect.svg";
import CoordinateSystemIcon from "./svg/coordinate-system.svg";
import CopyFileIcon from "./svg/copy-one.svg";
import CopyIcon from "./svg/copy.svg";
import CreditIcon from "./svg/credit.svg";
import CrownIcon from "./svg/crown.svg";
import DisconnectPayIcon from "./svg/cutting-one.svg";
import DeclinedFileIcon from "./svg/declined-file.svg";
import DeleteIcon from "./svg/delete.svg";
import DiscountIcon from "./svg/discount.svg";
import DocSearchIcon from "./svg/doc-search-two.svg";
import DollarIcon from "./svg/dollar.svg";
import DoubleRightIcon from "./svg/double-right.svg";
import DownIcon from "./svg/down.svg";
import DownloadIcon from "./svg/download.svg";
import DragIcon from "./svg/drag.svg";
import EditIcon from "./svg/edit.svg";
import EmptyDataIcon from "./svg/empty-data.svg";
import FilledEyesIcon from "./svg/eyes.svg";
import EyesIcon from "./svg/eyes2.svg";
import GoOnIcon from "./svg/go-on.svg";
import HIcon from "./svg/h.svg";
import HomeIcon from "./svg/home.svg";
import InfoIcon from "./svg/info.svg";
import LeftSmallIcon from "./svg/left-small.svg";
import LeftIcon from "./svg/left.svg";
import ListSuccessIcon from "./svg/list-success.svg";
import ListTopIcon from "./svg/list-top.svg";
import LockIcon from "./svg/lock.svg";
import MessageOneIcon from "./svg/message-one.svg";
import MinusIcon from "./svg/minus.svg";
import ThreeDotsIcon from "./svg/more-one.svg";
import NoResultsIcon from "./svg/no-results.svg";
import NotebookIcon from "./svg/notebook.svg";
import NotepadIcon from "./svg/notepad.svg";
import NotesIcon from "./svg/notes.svg";
import CashIcon from "./svg/paper-money-two.svg";
import TwoPeopleIcon from "./svg/peoples-one.svg";
import ThreePeopleIcon from "./svg/peoples-two.svg";
import PercentageIcon from "./svg/percentage.svg";
import PhoneTelephoneIcon from "./svg/phone-telephone.svg";
import PlusIcon from "./svg/plus.svg";
import PoundIcon from "./svg/pound.svg";
import ConnectPayIcon from "./svg/power-supply-one.svg";
import PreviewCloseIcon from "./svg/preview-close.svg";
import RefreshIcon from "./svg/refresh.svg";
import RemindIcon from "./svg/remind.svg";
import RightCIcon from "./svg/right-c.svg";
import RightSmallIcon from "./svg/right-small.svg";
import RightIcon from "./svg/right.svg";
import ScanningIcon from "./svg/scanning-two.svg";
import SearchIcon from "./svg/search.svg";
import SendEmailIcon from "./svg/send-email.svg";
import SettingIcon from "./svg/setting-two.svg";
import ShareIcon from "./svg/share.svg";
import ShoppingCartIcon from "./svg/shopping-cart-one.svg";
import PinkCartIcon from "./svg/shopping-cart-two.svg";
import ShuffleIcon from "./svg/shuffle.svg";
import StopwatchIcon from "./svg/stopwatch.svg";
import TextRecognitionIcon from "./svg/text-recognition.svg";
import TextureIcon from "./svg/texture.svg";
import TimeIcon from "./svg/time.svg";
import BlueTrendingIcon from "./svg/trending-custom.svg";
import TrendingIcon from "./svg/trending-up.svg";
import UndoIcon from "./svg/undo.svg";
import UpIcon from "./svg/up.svg";
import UploadIcon from "./svg/upload-two.svg";
import UserOutlineIcon from "./svg/user-two.svg";
import UserIcon from "./svg/user.svg";
import WaiverIcon from "./svg/writing-fluently.svg";

export {
  DragIcon,
  ConnectPayIcon,
  DocSearchIcon,
  DiscountIcon,
  DisconnectPayIcon,
  DeclinedFileIcon,
  CancelBookingIcon,
  TrendingIcon,
  UpIcon,
  UploadIcon,
  InfoIcon,
  CommissionIcon,
  TextureIcon,
  SearchIcon,
  CreditIcon,
  CheckCorrectIcon,
  RemindIcon,
  CashReportIcon,
  CashIcon,
  CheckSmallIcon,
  DownIcon,
  FilledEyesIcon,
  PreviewCloseIcon,
  NoResultsIcon,
  ColumnsIcon,
  DollarIcon,
  DownloadIcon,
  ChartRingIcon,
  NotebookIcon,
  AdjacentItemIcon,
  ShoppingCartIcon,
  ListSuccessIcon,
  UserIcon,
  CalendarIcon,
  NotepadIcon,
  ConnectIcon,
  AnalysisIcon,
  CoordinateSystemIcon,
  ThreePeopleIcon,
  CommunicationIcon,
  SettingIcon,
  DeleteIcon,
  AddUserIcon,
  AddNotesIcon,
  AddModeIcon,
  ApprovedCheckIcon,
  GreenCheckIcon,
  RedCloseIcon,
  EyesIcon,
  ComputerIcon,
  WaiverIcon,
  EmptyDataIcon,
  PlusIcon,
  MinusIcon,
  EditIcon,
  CloseIcon,
  HomeIcon,
  RightIcon,
  ScanningIcon,
  RightCIcon,
  CautionIcon,
  PhoneTelephoneIcon,
  MessageOneIcon,
  RightSmallIcon,
  LeftSmallIcon,
  CloseCircleIcon,
  AddIcon,
  CodeIcon,
  ListTopIcon,
  ThreeDotsIcon,
  StopwatchIcon,
  ShareIcon,
  NotesIcon,
  CopyIcon,
  ArrowLeftIcon,
  LeftIcon,
  BezierCurveIcon,
  CalendarDaysIcon,
  GoOnIcon,
  TimeIcon,
  AttentionIcon,
  CheckListIcon,
  PoundIcon,
  HIcon,
  TextRecognitionIcon,
  DoubleRightIcon,
  CaretDownIcon,
  BankCardIcon,
  BillIcon,
  BlueTrendingIcon,
  PinkCartIcon,
  TwoPeopleIcon,
  ArrowRightIcon,
  RefreshIcon,
  LockIcon,
  ShuffleIcon,
  SendEmailIcon,
  CityIcon,
  PercentageIcon,
  UserOutlineIcon,
  UndoIcon,
  CheckoutPlus,
  CrownIcon,
};
